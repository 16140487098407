.custom-marker-clustering-control-panel .attribution {
  font-size: 0.75em;
}

@keyframes appear {
  from {
    opacity: 0.5;
    scale: 0.8;
  }
  80% {
    scale: 1.05;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.custom-marker-clustering-map {
  width: 100vw;
  height: 92vh;
}

.custom-marker-clustering-map .marker {
  box-sizing: border-box;
  border-radius: 50%;
  padding: 8px;
  translate: 0 50%;
  border: 1px solid rgb(128, 126, 126);
  color: white;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  animation: appear 150ms both;
}

.custom-marker-clustering-map .marker svg {
  width: 100%;
  vector-effect: non-scaling-stroke;
}

.custom-marker-clustering-map .marker.feature {
  width: 40px;
  height: 40px;

  background-color: white;
  filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.5));
}

.custom-marker-clustering-map .marker.cluster {
  width: 56px;
  height: 56px;
  background-color: #f0b7f5;
  filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.7));
  overflow: hidden;
  padding-bottom: 0;
}

.custom-marker-clustering-map .marker.cluster span {
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  padding: 3px 6px;
  /* oversized by the padding of the container */
  width: calc(100% + 16px);
  text-align: center;
  margin-top: 6px;
  height: 30px;
}


.custom-marker-clustering-map .gm-style-iw {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.custom-marker-clustering-map .gm-style-iw-d {
  overflow: visible !important;
}

.custom-marker-clustering-map .gm-style-iw-t::after {
  background: transparent !important;
}

/* .custom-marker-clustering-map .gm-style-iw-tc::after {
  background: transparent !important;
} */

.custom-marker-clustering-map .gm-style-iw-c {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.custom-marker-clustering-map .gm-style-iw-t::before {
  display: none !important;
}

.custom-marker-clustering-map .gm-style-iw-tc::before {
  display: none !important;
}
/* "gm-style-iw gm-style-iw-c" */