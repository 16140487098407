/* .lilac-calendar {
    z-index: 0;
} */
 /* MobileCalendar.css */

/* Make the calendar responsive */
.fc {
    max-width: 100%;
    font-size: 14px;
  }

  .fc .fc-list-event-dot {
    display: none; /* or your preferred color */
  }

  .fc-header-toolbar {
    position: sticky;
    top: 0px; /* This ensures the header sticks to the top */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 10; /* Ensure it stays above other elements */
    background-color: #fff8f5; /* Assuming your page background is not white */
  }
  
  /* Round the edges of the calendar */
  .fc .fc-view-harness {
    border-radius: 10px;
    overflow: hidden;
  }
  
  /* Adjust header for mobile */
  .fc .fc-toolbar {
    flex-direction: row;
  }
  
  /* .fc .fc-toolbar-title {
    font-size: 0.2em;
    margin-bottom: 0.5em;
  } */
  
  .fc .fc-toolbar-title { 
    font-size: 12px; /* Adjust this value to make the font smaller */
  }
  
  /* Adjust day cell size for mobile */
  .fc .fc-daygrid-day {
    min-height: 4em;
  }
  
  /* Make event text smaller on mobile */
  .fc .fc-event-title {
    font-size: 0.8em;
  }
  
  .fc .fc-timegrid-slot {
      height: 2.5em;
      border-bottom: 0 !important;
  }

  .fc .fc-day-header {
    font-size: 0.1em;
  }

  .fc .fc-daygrid-day-number {
    position: relative;
    z-index: -1; /* Ensure it's above other elements */
  }

  /* Hide some elements on smaller screens */
  @media screen and (max-width: 768px) {
    .fc .fc-view-harness {
      height: auto !important;
    }
    
    /* .fc .fc-day-header {
      font-size: 0.1em;
    }
    
    .fc .fc-list-header {
      font-size: 0.6em;
    }
    .fc .fc-title{
      font-size: 0.6em;
  } */
    
    .fc .fc-daygrid-more-link {
      font-size: 0.8em;
    }

    /* Add this to your MobileCalendar.css */
  }
  
  .fc .fc-day-all-available {
    background-color: rgba(0, 255, 0, 0.1); /* Light green for enough time */
  }
  
  .fc .fc-day-some-available {
    background-color: rgba(255, 165, 0, 0.1); /* Light orange for some availability */
  }
  
  .fc .fc-day-none-available {
    background-color: rgba(255, 0, 0, 0.1); /* Light red for no available slots */
  }
  
  .fc .fc-day-no-shop-hours {
    background-color: rgba(128, 128, 128, 0.1); /* Grey for no shop hours */
  }
  
  /* Ensure the color doesn't override the current day highlighting */
  /* .fc .fc-day-today {
    background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15)) !important;
  } */

  /* Make buttons more touch-friendly */
  /* .fc .fc-button {
    padding: 0.5em 0.8em;
    font-size: 0.9em;
    margin: 0.2em;
  } */

  .fc .fc-button {
    background: #FFFFFF; /* White background for better contrast */
    /* border: 1px solid #E0E0E0; Light border for a clean look */
    color: #333333; /* Dark text for readability */
    padding: 6px 12px; /* Reduced padding for smaller buttons */
    font-size: 13px; /* Smaller font size for mobile */
    font-weight: 500; /* Semi-bold text for better visibility */
    margin: 0 0px; /* Reduced margin for less space between buttons */
    cursor: pointer;
    border-radius: 6px; /* Slightly less rounded for mobile */
    transition: all 0.2s ease; /* Faster transition for mobile */
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }


  .fc .fc-prev-button {
    background: #1E88E5; /* Blue for Previous */
    color: white;
  }

  .fc .fc-next-button {
    background: #1E88E5; /* Blue for Next */
    color: white;
  }

  .fc .fc-today-button {
    background: #4CAF50; /* Green for Today */
    color: white;
  }

  .fc .fc-dayGridMonth-button {
    background: #13007e; /* Yellow-green for Month */
    color: white;
  }

  .fc .fc-timeGridDay-button {
    background: #13007e; /* Orange for Day */
    color: white;
  }
  
  .fc .fc-listWeek-button {
    background: #13007e; /* Orange for Day */
    color: white;
  }
